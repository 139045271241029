import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Box } from "@twilio-paste/core/box";
import { TextArea } from "@twilio-paste/core/textarea";
import {FormEvent, useEffect, useState} from "react";
import { Button } from "@twilio-paste/core/button";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";
import React from "react"
import { sessionDataHandler } from "../sessionDataHandler";
import { addNotification, changeEngagementPhase, updatePreEngagementData } from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { Header } from "./Header";
import { notifications } from "../notifications";
import { NotificationBar } from "./NotificationBar";
import { introStyles, fieldStyles, titleStyles, formStyles } from "./styles/PreEngagementFormPhase.styles";
import Combobox from "react-widgets/Combobox";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import InputMask from "react-input-mask";
import styles from '../components/styles/Interactives.module.css'
import "react-widgets/styles.css";

export const PreEngagementFormPhase = () => {
    const [matters, setMatters] = useState([]);
    const [serviceTime, setServicetime] = useState([]);

    useEffect(() => {
        fetch("https://futebolcard-webchat-4432.twil.io/subjects.json")
            .then(res => res.json())
            .then(
                (data) => {
                    setMatters(data);
                },
                (error) => {
                    console.log(error)
                }
            )
    }, [])


    const { nome, email, telefone, cpf_cnpj, n_pedido, time, assunto } = useSelector((state: AppState) => state.session.preEngagementData) || {};
    const dispatch = useDispatch();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: {
                    friendlyName:nome,
                    nome,
                    email,
                    telefone,
                    cpf_cnpj,
                    n_pedido,
                    time,
                    assunto
                }
            });
            dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    return (
        <>
            <Header />
            <NotificationBar />
            <Box as="form" data-test="pre-engagement-chat-form" onSubmit={handleSubmit} {...formStyles}>
                <Text {...titleStyles} as="h3">
                    Olá!
                </Text>
                <Text {...introStyles} as="p">
                    Favor preencher as informações abaixo para iniciar o chat.
                </Text>
                <Box {...fieldStyles}>
                    <Label htmlFor="nome">Nome *</Label>
                    <Input
                        type="text"
                        placeholder="Digite seu nome"
                        name="nome"
                        data-test="pre-engagement-chat-form-name-input"
                        value={nome}
                        onChange={(e) => dispatch(updatePreEngagementData({ nome: e.target.value }))}
                        required
                    />
                </Box>
                <Box {...fieldStyles}>
                    <Label htmlFor="email">E-mail *</Label>
                    <Input
                        type="email"
                        placeholder="Digite seu e-mail"
                        name="email"
                        data-test="pre-engagement-chat-form-email-input"
                        value={email}
                        onChange={(e) => dispatch(updatePreEngagementData({ email: e.target.value }))}
                        required
                    />
                </Box>
                <Box {...fieldStyles}>
                    <Label htmlFor="telefone">Telefone *</Label>
                    <InputMask
                        className={styles.inputBoxMask}
                        type="text"
                        mask="(99) 99999-9999"
                        placeholder="Digite seu telefone com DDI"
                        name="telefone"
                        data-test="pre-engagement-chat-form-name-input"
                        value={telefone}
                        onChange={(e: { target: { value: any; }; }) => dispatch(updatePreEngagementData({ telefone: e.target.value }))}
                        required />
                </Box>
                <Box {...fieldStyles}>
                    <Label htmlFor="cpf_cnpj">CPF *</Label>
                    <InputMask
                        className={styles.inputBoxMask}
                        type="text"
                        mask="999.999.999-99"
                        placeholder="Digite seu CPF"
                        name="cpf_cnpj"
                        data-test="pre-engagement-chat-form-name-input"
                        value={cpf_cnpj}
                        onChange={(e: { target: { value: any; }; }) => dispatch(updatePreEngagementData({ cpf_cnpj: e.target.value }))}
                        required />
                </Box>
                <Box {...fieldStyles}>
                    <Label htmlFor="n_pedido">Nº Pedido</Label>
                    <Input
                        type="text"
                        placeholder="Digite o número do seu pedido"
                        name="n_pedido"
                        data-test="pre-engagement-chat-form-name-input"
                        value={n_pedido}
                        onChange={(e) => dispatch(updatePreEngagementData({ n_pedido: e.target.value }))}
                    />
                </Box>
                <Box {...fieldStyles}>
                    <Label htmlFor="time">Time</Label>
                    <Input
                        type="text"
                        placeholder="Digite seu time"
                        name="time"
                        data-test="pre-engagement-chat-form-name-input"
                        value={time}
                        onChange={(e) => dispatch(updatePreEngagementData({ time: e.target.value }))}
                    />
                </Box>
                <Box {...fieldStyles}>
                    <Label htmlFor="assunto">Assunto *</Label>
                    <Combobox
                        data={matters}
                        dataKey='value'
                        textField='label'
                        name='assunto'
                        data-test="pre-engagement-chat-form-name-input"
                        value={assunto}
                        onChange={(e:any) => dispatch(updatePreEngagementData({ assunto: e.value }))}
                        aria-required={true}
                    />
                </Box>

                <Button variant="primary" type="submit" data-test="pre-engagement-start-chat-button" style={{backgroundColor: "#e55f08"}}>
                    Iniciar
                </Button>

            </Box>
        </>
    );
};

import { Box } from "@twilio-paste/core/box";
import styles from '../components/styles/Interactives.module.css'
import React, {useContext, useEffect, useState} from "react";
import MessageContext from "./context/MessageContext";

interface InteractiveSelectionProps {
    options: any
}

export const InteractiveSelection: React.FC<InteractiveSelectionProps> = props => {

    const [hideSelections, setHideSelections] = useState<boolean>(false)
    const messageContext  = useContext(MessageContext)

    useEffect( () => {
        setHideSelections(false)
    }, [props.options])

    const selectOption = (e: any) => {
        messageContext.setAlternativeSelection({id: e.target.id, text: e.target.textContent})
        setHideSelections(true)
    }

    return (
        <div>
        {
            !hideSelections ?
                <Box className={styles.single_choice_box}>
                    {
                        Array.isArray(props.options) ?
                            props.options.map(option => (
                                <div onClick={selectOption} key={Math.random()}  id={option.id}  tabIndex={1} className={styles.single_choice}>{option.text}</div>
                            ))
                            : null
                    }
                </Box>
                : null
        }
        </div>
    )
}
import React, { createContext } from "react";

interface IMessageContext {
    alternativeSelection: {
        id: string,
        text: string
    };
    action: string;
    setAction: any;
    setAlternativeSelection: any;
    message:string;
    setMessage:any;
}

const Message = {
    alternativeSelection: {
        id: "",
        text: ""
    },
    action: "render-options",
    setAction: () => null,
    setAlternativeSelection: () => null,
    message: "",
    setMessage: () => null
}

const MessageContext = createContext<IMessageContext>(Message);
export default MessageContext;




import {useEffect, Fragment, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import React, { createContext } from "react";
import { Header } from "./Header";
import { MessageList } from "./MessageList";
import { MessageInput } from "./MessageInput";
import { AppState } from "../store/definitions";
import { ConversationEnded } from "./ConversationEnded";
import { NotificationBar } from "./NotificationBar";
import { removeNotification } from "../store/actions/genericActions";
import { notifications } from "../notifications";
import { AttachFileDropArea } from "./AttachFileDropArea";
import MessageContext from "./context/MessageContext";

export const MessagingCanvasPhase = () => {

    const [alternativeSelection, setAlternativeSelection] = useState({id: "", text: ""})
    const [action, setAction] = useState<string>("render-options")
    const [message, setMessage] = useState<string>("")

    const dispatch = useDispatch();
    const conversationState = useSelector((state: AppState) => state.chat.conversationState);

    useEffect(() => {
        dispatch(removeNotification(notifications.failedToInitSessionNotification("ds").id));
    }, [dispatch]);

    const Wrapper = conversationState === "active" ? AttachFileDropArea : Fragment;

    return (
        <MessageContext.Provider value={{ alternativeSelection, setAlternativeSelection, action, setAction, message, setMessage }}>
            <Wrapper>
                <Header />
                <NotificationBar />
                <MessageList />
                {(conversationState === "active" && action === "render-options") ? <MessageInput /> : <ConversationEnded />}
            </Wrapper>
        </MessageContext.Provider>
    );
};

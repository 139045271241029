import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import React, {useContext} from "react";
import { containerStyles, titleStyles } from "./styles/Header.styles";
import { AiOutlineMenuFold } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";
import MessageContext from "./context/MessageContext";
import {useSelector} from "react-redux";
import {AppState} from "../store/definitions";

export const Header = ({ customTitle }: { customTitle?: string }) => {

    const messageContext = useContext(MessageContext)
    function endChat(){
        messageContext.setAction("end-chat")
        messageContext.setMessage("Se você ainda tiver dúvidas, não hesite entrar em contato.")
    }

    const { currentPhase, expanded } = useSelector(({ session }: AppState) => ({
        currentPhase: session.currentPhase,
        expanded: session.expanded
    }));

    return (
        <Box as="header" {...containerStyles} style={{backgroundColor: "#e55f08", paddingRight: "20px"}}>
            <Text as="h2" {...titleStyles}>
                {customTitle || "Chat online"}
            </Text>
            {
                currentPhase === "MessagingCanvas" ?
                    <div style={{display: "flex", columnGap: "20px"}}>
                        <div style={{display: "flex", alignItems: "center", visibility: "hidden"}}>
                            <AiOutlineMenuFold color={"#ffffff"} size={"20px"}/>
                            <h1 style={{fontSize: "10px", color: "#ffffff"}}>Voltar ao menu</h1>
                        </div>
                        <label onClick={endChat} style={{display: "flex", alignItems: "center", marginLeft: "-15px"}}>
                            <IoIosCloseCircleOutline color="ffffff" size={"20px"}/>
                            <h1 style={{fontSize: "10px", color: "#ffffff" }}>Encerrar chat</h1>
                        </label>
                    </div>
                :null
            }

        </Box>
    );
};
